import React, { useCallback, useRef } from 'react';

import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import Switch from '@material-ui/core/Switch/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import { Meeting } from '../../CalendialMeeting/CalendialMeeting';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string; meeting?: Meeting }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const disabled = !hasVideoInputDevices || props.disabled;

  function VideoIconComputed() {
    const isActive = isVideoEnabled && !disabled;
    if (isActive) {
      return <VideoOnIcon />;
    }
    return <VideoOffIcon />;
  }

  return (
    <button className="btn p-4" style={{ position: 'relative' }} onClick={toggleVideo}>
      <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <VideoIconComputed />
      </span>
    </button>
  );
}
