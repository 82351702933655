import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import CalendialMeeting, { Area, Meeting, ScheduledMeeting } from '../CalendialMeeting/CalendialMeeting';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}
export interface PreJoinScreensProps {
  meeting?: Meeting;
  scheduledMeeting?: ScheduledMeeting;
}

export default function PreJoinScreens({ meeting, scheduledMeeting }: PreJoinScreensProps) {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const { URLUserName } = useParams<{ URLUserName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = () => {
    //event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    /*if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`/room/${roomName}/${name}${window.location.search || ''}`)
      );
    }*/
    setStep(Steps.deviceSelectionStep);
  };

  const SubContent = (
    <>
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  let area;

  if (typeof meeting !== 'undefined' && typeof scheduledMeeting !== 'undefined') {
    var MeetingSummary = (
      <>
        <CalendialMeeting meeting={meeting} scheduledMeeting={scheduledMeeting} />
      </>
    );
    area = meeting.area;
  } else {
    var MeetingSummary = <></>;
  }

  return (
    <IntroContainer
      subContent={step === Steps.deviceSelectionStep && SubContent}
      meeting={meeting}
      scheduledMeeting={scheduledMeeting}
    >
      {meeting && step === Steps.roomNameStep && (
        <>
          {MeetingSummary}
          <MediaErrorSnackbar error={mediaError} />
          <RoomNameScreen
            name={URLUserName || ''}
            roomName={roomName}
            setName={setName}
            setRoomName={setRoomName}
            handleSubmit={handleSubmit}
          />
        </>
      )}

      {meeting && step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} meeting={meeting} />
      )}

      {!meeting && 'Conférence et participant inconnu'}
    </IntroContainer>
  );
}
