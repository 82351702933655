import React from 'react';
import { Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import Swoosh from './swoosh';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import CalendialMeeting, { Area, Meeting, ScheduledMeeting, getColorVars } from '../CalendialMeeting/CalendialMeeting';
import Moment from 'react-moment';

let defaultInfoWindowBackground = Swoosh;

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    backgroundImage: defaultInfoWindowBackground,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  subContentContainer: {
    bottom: '1em',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      marginTop: '1em',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
  meeting?: Meeting;
  scheduledMeeting?: ScheduledMeeting;
}

const IntroContainer = (props: IntroContainerProps) => {
  const { user } = useAppState();
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      {props.meeting && props.scheduledMeeting && (
        <div className={'theme-' + props.meeting.area.theme}>
          <style>{getColorVars(props.meeting.area)}</style>
          <div id="app" className="app-wrapper">
            <div className="halo-1"></div>
            <div className="halo-2"></div>
            <div className="container">
              <div className="app-content">
                <div className="row mb-5 mb-md-6">
                  <div className="col d-flex align-items-center">
                    <img src={props.meeting.area.logo.content_url} className="logo" alt={props.meeting.area.name} />
                    {props.meeting.welcome_text_video && (
                      <p className="typo-1 ms-6 welcome-text-visio">{props.meeting.welcome_text_video}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="panel bg-xs p-md-6">
                      <div className="row gx-3">
                        <div className="col-md-6">
                          <CalendialMeeting meeting={props.meeting} scheduledMeeting={props.scheduledMeeting} />
                        </div>
                        <div className="col-md-6">
                          <div>{props.children}</div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IntroContainer;
