import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event?: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  let URLUserName: any;
  ({ URLUserName } = useParams());
  if (URLUserName !== '' && typeof URLUserName !== 'undefined') {
    setName(URLUserName);
  }

  const hasUsername = typeof URLUserName !== 'undefined';

  // Force bypass ogf first screen.
  handleSubmit();

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!hasUsername && (
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-user-name">
              Votre nom
            </InputLabel>
            <TextField
              id="input-user-name"
              variant="outlined"
              fullWidth
              size="small"
              value={name}
              onChange={handleNameChange}
            />
          </div>
        )}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={!name || !roomName}
          className={classes.continueButton}
        >
          {!hasUsername && 'Rejoindre'}
          {hasUsername && 'Rejoindre entant que ' + name}
        </Button>
      </form>
    </>
  );
}
