import React, { createContext, useContext, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import { Meeting, ScheduledMeeting } from '../../components/CalendialMeeting/CalendialMeeting';
import { useParams } from 'react-router-dom';

export interface ApendayContextType {
  meeting?: Meeting;
  scheduledMeeting?: ScheduledMeeting;
  isAdmin: boolean;
  hasLockedBackground: boolean;
}

export const ApendayContext = createContext<ApendayContextType>(null!);

async function getApendayContext({
  roomName,
  userName,
}: {
  roomName?: string;
  userName?: string;
}): Promise<ApendayContextType> {
  const returnValue = { isAdmin: false } as ApendayContextType;

  if (typeof roomName == 'undefined') {
    return returnValue;
  }

  let meetingData = roomName.match(/^([a-z0-9-_]+)_([a-z0-9-]+)$/) || [];
  const headers = new window.Headers();
  headers.append('Authorization', '' + process.env.REACT_APP_CALENDIAL_API_CREDENTIAL);
  const meetingEndpoint = process.env.REACT_APP_CALENDIAL_BASE_URL + '/api/front/meeting/' + meetingData[1];

  const fetchMeeting = fetch(`${meetingEndpoint}`, { headers }).then(async res => {
    const data = (await res.json()) as Meeting;

    data.styles = makeStyles((theme: Theme) => ({
      button: {
        backgroundColor: data.area.cta_background_color + ' !important',
        color: data.area.cta_text_color + ' !important',
        border: '0 !important',
        height: '36px',
        minWidth: '64px',
        padding: '0 16px',
        letterSpacing: '0',
        textTransform: 'uppercase',
      },
    }));
    if (data.area.visio_background) {
      returnValue.hasLockedBackground = true;
    }

    returnValue.meeting = data;
  });

  const scheduledMeetingEndpoint =
    process.env.REACT_APP_CALENDIAL_BASE_URL + '/api/front/meeting/' + meetingData[1] + '/' + meetingData[2];
  const fetchScheduleMeeting = await fetch(`${scheduledMeetingEndpoint}`, { headers }).then(async res => {
    const data = await res.json();

    returnValue.scheduledMeeting = data;

    for (let consultant of data.consultants) {
      if (`${consultant.first_name} ${consultant.last_name}` === userName) {
        returnValue.isAdmin = true;
        continue;
      }
    }
  });

  return Promise.all([fetchMeeting, fetchScheduleMeeting]).then(responses => returnValue);
}

export default function ApendayStateProvider(props: React.PropsWithChildren<{}>) {
  const [meeting, setMeeting] = useState<Meeting>();
  const [scheduledMeeting, setScheduledMeeting] = useState<ScheduledMeeting>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasLockedBackground, setHasLockedBackground] = useState<boolean>(false);
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const { URLUserName } = useParams<{ URLUserName?: string }>();

  if (!meeting || !scheduledMeeting) {
    getApendayContext({ roomName: URLRoomName, userName: URLUserName }).then(contextValues => {
      if (!meeting && contextValues.meeting) {
        setMeeting(contextValues.meeting);
      }
      if (!scheduledMeeting && contextValues.scheduledMeeting) {
        setScheduledMeeting(contextValues.scheduledMeeting);
        setIsAdmin(contextValues.isAdmin);
        setHasLockedBackground(contextValues.hasLockedBackground && contextValues.isAdmin);
      }
    });
  }

  let contextValue = { isAdmin: isAdmin, hasLockedBackground: hasLockedBackground } as ApendayContextType;
  return (
    <ApendayContext.Provider value={{ ...contextValue, meeting, scheduledMeeting }}>
      {props.children}
    </ApendayContext.Provider>
  );
}

export function useApendayState() {
  const context = useContext(ApendayContext);
  if (!context) {
    throw new Error('useApendayState must be used within the ApendayStateProvider');
  }
  return context;
}
