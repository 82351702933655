import React, { useState } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Moment from 'react-moment';
import { makeStyles, Theme } from '@material-ui/core';
import forEach from 'lodash/forEach';
import kebabCase from 'lodash/kebabCase';
import moment, { DurationInputArg2, min, unitOfTime } from 'moment';

type DurationUnit = 'minute' | 'hour' | 'day' | 'month' | 'year';

export interface Meeting {
  name: string;
  description: string;
  duration: number;
  duration_unit: DurationUnit;
  welcome_text_video?: string;
  welcome_text_app?: string;
  area: Area;
  styles: () => ClassNameMap;
}

export interface ConsultantCategory {
  name: string;
}

export interface Consultant {
  first_name: string;
  last_name: string;
  email_address: string;
  function: ConsultantCategory;
  service: ConsultantCategory;
}

export interface ScheduledMeeting {
  id: string;
  consultant: Consultant;
  consultants: Array<Consultant>;
  date: string;
}

export interface MediaObject {
  content_url: string;
}

export interface Area {
  name: string;
  logo: MediaObject;
  visio_logo?: MediaObject;
  visio_background?: MediaObject;
  primary_color: string;
  secondary_color: string;
  background_color: string;
  cta_background_color: string;
  cta_text_color: string;
  back_button_background_color: string;
  back_button_arrow_color: string;
  theme: string;
}

export interface MeetingStyles {
  button: string;
  logo_file_name: string;
}

export interface CalendialMeetingProps {
  meeting: Meeting;
  scheduledMeeting: ScheduledMeeting;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    width: '45px',
  },
}));

export function getColorVars(area: Area): string {
  forEach(area, function(value, key) {
    if (typeof value === 'string' && value.charAt(0) === '#') {
      document.documentElement.style.setProperty('--' + kebabCase(key), value);
      document.documentElement.style.setProperty('--' + kebabCase(key) + '--dark', LightenDarkenColor(value, -20));
      document.documentElement.style.setProperty('--' + kebabCase(key) + '--light', LightenDarkenColor(value, 20));
    }
  });

  document.documentElement.classList.add('theme-' + area.theme);

  return (
    'body {' +
    '--primary-color: ' +
    area.primary_color +
    ';' +
    '--primary-color--dark: #2ea0e6;' +
    '--primary-color--light: #56c8ff;' +
    '--secondary-color: ' +
    area.secondary_color +
    ';' +
    '--secondary-color--dark:#686565;' +
    '--secondary-color--light:#908d8d;' +
    '--body-background-color: ' +
    area.background_color +
    ';' +
    '--body-background-color--dark:#0;' +
    '--body-background-color--light:#141414;' +
    '--grey-icon-color: #C4C4C4' +
    '}'
  );
}

function LightenDarkenColor(col: string, amt: number) {
  var usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  var b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  var g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

function formatDuration(minutes: number): string {
  const duration = moment.duration(minutes, 'minutes');
  const remainingMinutes = minutes % 60;
  if (remainingMinutes !== 0) {
    return (
      duration.subtract(remainingMinutes, 'minutes').asHours() +
      'h' +
      moment.duration(remainingMinutes, 'minutes').asMinutes()
    );
  } else {
    return duration.humanize({ m: 60, h: 24 });
  }
}

export default function CalendialMeeting({ meeting, scheduledMeeting }: CalendialMeetingProps) {
  const classes = useStyles();
  const date = new Date(scheduledMeeting.date);
  return (
    // <div className="scroll-wrap-form">
    <div>
      <div className="row mb-4">
        <p className="typo-2">
          <span className="label">{meeting.name}</span>
        </p>
      </div>
      <div className="row mb-4">
        <div className={'col-auto ' + classes.iconWrapper}>
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" className="icon icon-clock me-3">
            <path
              clipRule="evenodd"
              d="M9 16.51c4.142 0 7.5-3.36 7.5-7.505A7.502 7.502 0 009 1.501c-4.142 0-7.5 3.36-7.5 7.504A7.502 7.502 0 009 16.509z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M9 4.502v4.503l2.25 2.251"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
        <div className="col p-marging">
          <p className="font-v-1">
            <span className="label duration">{formatDuration(meeting.duration)}</span>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className={'col-auto ' + classes.iconWrapper}>
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" className="icon icon-camera me-3">
            <path
              clipRule="evenodd"
              d="M18.182 5.253l-5.25 3.752 5.25 3.752V5.253v0z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              clipRule="evenodd"
              d="M1.682 5.752a2 2 0 012-2h7.25a2 2 0 012 2v6.506a2 2 0 01-2 2h-7.25a2 2 0 01-2-2V5.752z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
        <div className="col p-marging">
          <p className="font-v-1">
            <span className="label duration">Visioconférence</span>
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col p-marging">
          {scheduledMeeting.consultants.map((consultant, i) => {
            return (
              <div key={i}>
                <p className="typo-2">{consultant.first_name + ' ' + consultant.last_name}</p>
                {consultant.function && <p className="font-v-2">{consultant.function.name}</p>}
              </div>
            );
          })}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col p-marging">
          <p className="typo-2 text-capitalize">
            <Moment locale="fr" date={new Date(scheduledMeeting.date)} format={'dddd D MMMM Y'} />
          </p>
          <p className="font-v-2">
            <span>
              <Moment date={new Date(scheduledMeeting.date)} format={'H:mm'} />
            </span>
            <span> - </span>
            <span>
              {moment(scheduledMeeting.date)
                .add(meeting.duration, 'minute')
                .format('H:mm')}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
