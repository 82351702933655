import React from 'react';

import BackgroundIcon from '../../../icons/BackgroundIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function BackgroundButton(props: { disabled?: boolean; className?: string }) {
  const { setIsBackgroundSelectionOpen, isBackgroundSelectionOpen } = useVideoContext();
  return (
    <button
      className="btn p-4"
      style={{ position: 'relative' }}
      onClick={() => setIsBackgroundSelectionOpen(!isBackgroundSelectionOpen)}
    >
      <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <BackgroundIcon />
      </span>
    </button>
  );
}
