import React from 'react';

import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Switch from '@material-ui/core/Switch/Switch';
import { Meeting } from '../../CalendialMeeting/CalendialMeeting';
import withStyles from '@material-ui/core/styles/withStyles';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string; meeting?: Meeting }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const disabled = !hasAudioTrack || props.disabled;

  function MicIconComputed() {
    const isActive = isAudioEnabled && !disabled;
    if (isActive) {
      return <MicIcon />;
    }
    return <MicOffIcon />;
  }

  return (
    <button className="btn p-4" style={{ position: 'relative' }} onClick={toggleAudioEnabled}>
      <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <MicIconComputed />
      </span>
    </button>
  );
}
