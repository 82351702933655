import React from 'react';
import { makeStyles, Typography, Grid, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { Meeting } from '../../CalendialMeeting/CalendialMeeting';
import { isSupported } from '@twilio/video-processors';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import BackgroundSelectionDialog from '../../BackgroundSelectionDialog/BackgroundSelectionDialog';
import BackgroundButton from '../../Buttons/BackgroundButton/BackgroundButton';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { useApendayState } from '../../../hooks/useApendayState/useApendayState';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  joinButtons: {
    textAlign: 'right',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0.5em 0',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
  meeting?: Meeting;
}

export default function DeviceSelectionScreen({ name, roomName, setStep, meeting }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { isAdmin: isAdmin } = useApendayState();

  const meetingStyles = meeting && meeting.styles();

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Chargement de la visio-conférence
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <LocalVideoPreview identity={name} />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <SettingsMenu mobileButtonClass={classes.deviceButton} />
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} meeting={meeting} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} meeting={meeting} />
              {isSupported && !isAdmin && <BackgroundButton />}
            </Hidden>
          </div>
          <div className="btn-group">
            <Hidden smDown>
              <SettingsMenu mobileButtonClass={classes.deviceButton} />
              <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} meeting={meeting} />
              <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} meeting={meeting} />
              {isSupported && !isAdmin && <BackgroundButton />}
            </Hidden>
          </div>
        </div>
        <div className="col-6">
          <button data-cy-join-now onClick={handleJoin} disabled={disableButtons} className="btn btn-primary w-100">
            Commencer
          </button>
        </div>
      </div>
      <BackgroundSelectionDialog meeting={meeting} />
    </>
  );
}
