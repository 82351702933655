import React, { useState, useRef } from 'react';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../icons/SettingsIcon';

export default function SettingsMenu({ mobileButtonClass }: { mobileButtonClass?: string }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <button
        ref={anchorRef}
        className="btn p-4"
        onClick={() => setDeviceSettingsOpen(true)}
        style={{ position: 'relative' }}
      >
        <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
          <SettingsIcon />
        </span>
      </button>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
