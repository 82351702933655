import React, { useState } from 'react';
import BackgroundSelectionHeader from './BackgroundSelectionHeader/BackgroundSelectionHeader';
import BackgroundThumbnail from './BackgroundThumbnail/BackgroundThumbnail';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { backgroundConfig } from '../VideoProvider/useBackgroundSettings/useBackgroundSettings';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Meeting } from '../CalendialMeeting/CalendialMeeting';
import { useApendayState } from '../../hooks/useApendayState/useApendayState';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    display: 'flex',
    width: theme.rightDrawerWidth,
    height: `calc(100% - ${theme.footerHeight}px)`,
  },
  thumbnailContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    overflowY: 'auto',
  },
}));

export interface BackgroundSelectionDialogProps {
  meeting?: Meeting;
}

function BackgroundSelectionDialog({ meeting }: BackgroundSelectionDialogProps) {
  const classes = useStyles();
  const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } = useVideoContext();
  const { hasLockedBackground } = useApendayState();
  const imageNames = backgroundConfig.imageNames;
  const images = backgroundConfig.images;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isBackgroundSelectionOpen}
      transitionDuration={0}
      classes={{
        paper: classes.drawer,
      }}
    >
      <BackgroundSelectionHeader onClose={() => setIsBackgroundSelectionOpen(false)} />
      <div className={classes.thumbnailContainer}>
        <BackgroundThumbnail thumbnail={'none'} name={'Aucun'} />
        <BackgroundThumbnail thumbnail={'blur'} name={'Flou'} />
        {!hasLockedBackground &&
          images.map((image, index) => (
            <BackgroundThumbnail
              thumbnail={'image'}
              name={imageNames[index]}
              index={index}
              imagePath={image}
              key={image}
            />
          ))}
      </div>
    </Drawer>
  );
}

export default BackgroundSelectionDialog;
