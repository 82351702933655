import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import { Meeting } from '../CalendialMeeting/CalendialMeeting';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    minHeight: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  meetingName: {
    flex: '0 0 50%',
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 100%',
      marginTop: '5px',
      marginBottom: '5px',
      '& br': {
        display: 'none',
      },
    },
  },
  blocRight: {
    flex: '0 0 50%',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 100%',
      marginTop: '5px',
      marginBottom: '5px',
      textAlign: 'center',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

export interface MobileTopMenuBarProps {
  meeting?: Meeting;
}

export default function MobileTopMenuBar({ meeting }: MobileTopMenuBarProps) {
  const classes = useStyles();
  const MeetingName = meeting ? meeting.name : '';
  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
      <div dangerouslySetInnerHTML={{ __html: MeetingName }} className={classes.meetingName} />
      <div className={classes.blocRight}>
        <EndCallButton className={classes.endCallButton} />
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </Grid>
  );
}
